<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='abs m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.about-us.e9857647') }}</div>
        </div>
        <card-element class='mt-24'>
          <div class='html-content'
            v-html='aboutUs'>
          </div>
        </card-element>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation
    },

    data: () => ({
      aboutUs: ''
    }),

    computed: {
      locale () {
        return this.$i18n.locale
      }
    },

    watch: {
      locale: {
        async handler (locale) {
          let content = await import(`@/contents/${locale}/about-us.md`)
          this.aboutUs = content.default
        },
        immediate: true
      }
    }
  }
</script>

<style lang='scss' scoped>
  .abs {
    max-width: 480px;
  }
</style>
