<template>
  <div>
    <div class='oft'></div>
    <div class='mus fixed right-0 bottom-0 left-0 z-400 text-white bg-primary'>
      <div class='sub flex m-auto'>
        <router-link class='flex flex-col items-center justify-center w-full'
          :to='{ name: "home" }'>
          <store-svg class='jow fill-current'/>
          <div class='mt-4 font-bold'>{{ $t('common.store') }}</div>
        </router-link>
        <router-link class='flex flex-col items-center justify-center w-full'
          :to='{ name: "esims" }'>
          <memory-svg class='jow fill-current'/>
          <div class='mt-4 font-bold'>{{ $t('common.my-esims') }}</div>
        </router-link>
        <router-link class='flex flex-col items-center justify-center w-full'
          :to='{ name: "settings" }'>
          <person-svg class='jow fill-current'/>
          <div class='mt-4 font-bold'>{{ $t('common.profile') }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import Memory from '@/assets/graphics/memory.svg'
  import Person from '@/assets/graphics/person.svg'
  import Store from '@/assets/graphics/store.svg'

  export default {
    components: {
      'memory-svg': Memory,
      'person-svg': Person,
      'store-svg': Store
    }
  }
</script>

<style lang='scss' scoped>
  .oft {
    height: 96px;
  }

  .mus {
    box-shadow: 0 -4px 3px 0 rgba(0, 0, 0, 0.1);
  }

  .sub {
    max-width: 480px;
    height: 96px;
  }

  .jow {
    height: 40px;
  }

  @screen desktop {
    .oft {
      @apply hidden;
    }

    .mus {
      @apply hidden;
    }
  }
</style>
