<template>
  <div>
    <div class='pot'></div>
    <div class='nar fixed top-0 right-0 left-0 z-400 px-24 bg-white shadow'>
      <div class='soa flex items-center justify-between m-auto'>
        <a href=''>
          <img class='ims'
            src='images/b93e.png'>
        </a>
        <div class='flex'>
          <template v-if='user'>
            <router-link class='flex hover:text-primary cursor-pointer'
              :to='{ name: "esims" }'>
              <i class='icon text-gray-700'>memory</i>
              <div class='ml-16 font-bold'>{{ $t('common.my-esims') }}</div>
            </router-link>
            <router-link class='flex ml-48 hover:text-primary cursor-pointer'
              :to='{ name: "settings" }'>
              <i class='icon text-gray-700'>person_outline</i>
              <div class='ml-16 font-bold'>{{ $t('common.profile') }}</div>
            </router-link>
          </template>
          <template v-else>
            <router-link class='flex hover:text-primary cursor-pointer'
              :to='{ name: "login" }'>
              <i class='icon text-gray-700'>vpn_key</i>
              <div class='ml-16 font-bold'>{{ $t('common.log-in') }} / {{ $t('common.sign-up') }}</div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      user () {
        return this.$store.state.auth.user
      }
    }
  }
</script>

<style lang='scss' scoped>
  .pot {
    @apply hidden;
  }

  .nar {
    @apply hidden;
  }

  @screen desktop {
    .pot {
      height: 96px;
      @apply block;
    }

    .nar {
      @apply block;
    }

    .soa {
      max-width: 840px;
      height: 96px;
    }

    .ims {
      height: 40px;
    }
  }
</style>
